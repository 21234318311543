<template>
  <v-btn
    class="btn"
    :min-width="width"
    elevation="0"
    :class="style"
    @click="$emit('click')"
    :to="to"
  >
    <slot>Submit</slot>
  </v-btn>
</template>

<script>
export default {
  name: "SoloButton",
  props: {
    to: {},
    outlined: {
      type: Boolean,
      default: false,
    },
    width: {
      default: 335,
    },
    inverse: {
      default: false,
      type: Boolean,
    },
    inverseOutlined: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    style() {
      if (this.inverse) {
        return "inverse";
      }

      if (this.outlined) {
        return "outline";
      }

      if (this.inverseOutlined) {
        return "inversed-outline";
      }

      return "default";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 20px !important;
  height: 123px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 36px !important;
  text-transform: capitalize;
}
.inverse {
  background-color: #ffffff !important;
  color: var(--v-primary-base);
}
.default {
  background: var(--v-primary-base) !important;
  color: #ffffff;
}
.outline {
  border: 6px solid var(--v-primary-base) !important;
  color: var(--v-primary-base);
  background: transparent !important;
}
.inversed-outline {
  border: 6px solid #ffffff !important;
  color: #ffffff;
  background: transparent !important;
}
</style>