import store from '@/store'
import translations from '@/assets/translations'

var translationMixin = {
    filters: {
        name(value) {
            if(typeof value === 'object') {
                return value[store.getters['app/getLocale']]
            }            
            return value
        },
        modifiers(value) {
            const vm = translationMixin 
            return value.map(modifier => {
                return vm.methods.name(modifier.name)
            }).join(', ')
        },
        price(value) {
            if(value) {
                const vm = translationMixin                
                return `+ ${value} ${vm.methods.translate('sr', 'SAR')}`
            }
        },
        itemPrice(value) {
            if(value) {
                const vm = translationMixin                
                return `${value} ${vm.methods.translate('sr', 'SAR')}`
            }
        },
        cal(value) {
            if(value && value > 0) {
                const vm = translationMixin                
                return `(${value} ${vm.methods.translate('cal', 'cal')})`
            }
        },
        charAt(value, i = 0) {
            return value.charAt(i)
        }
    },    
    computed: {
        total() {
            return store.getters['cart/items'].map(({total, quantity}) => {
                return total * quantity
            }).reduce((a,b) => { return a + b }, 0)
        },
        cartItems() {
            return store.getters['cart/items'].map(({total, quantity}) => {
                return quantity
            }).reduce((a,b) => { return a + b }, 0)
        }
    },
    methods: {
        translate(key ='' , defaultText = '') {
            let locales = {
                'en-us': 'en_US',
                'ar-sa': 'ar_SA'
            }
            let text = translations.data[0]?.fields?.[key]?.[locales[store.getters['app/getLocale']]]
            if(text) {
                return text
            }
            return defaultText    
        },
        total_vat_included(text, total) {            
            return text.replace('_TOTAL_ TOTAL', total)
        },
        name(value) {
            return value[store.getters['app/getLocale']]
        }
    }
}

export default translationMixin