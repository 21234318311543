export default {
   "data":[
      {
         "itemId":"12bb519c-4db9-11ec-82fa-0ac61be7e275",
         "status":"published",
         "position":null,
         "createdAt":"2021-11-25T06:29:42+0000",
         "fields":{
            "which_lang":{
               "en_US":"Which language do your prefer?",
               "ar_SA":"\u0623\u064a \u0644\u063a\u0629 \u062a\u0631\u064a\u062f\u061f"
            },
            "restart_order":{
               "en_US":"Restart Order",
               "ar_SA":"\u0625\u0628\u062f\u0623 \u0645\u0646 \u062c\u062f\u064a\u062f"
            },
            "confirm_reset_text":{
               "en_US":"Are you sure you want to reset? Your orders will be removed and you will be taken to the homepage",
               "ar_SA":"\u0647\u0644 \u0623\u0646\u062a \u0645\u062a\u0623\u0643\u062f \u0623\u0646\u0643 \u062a\u0631\u064a\u062f \u0623\u0646 \u062a\u0628\u062f\u0623 \u0645\u0646 \u062c\u062f\u064a\u062f\u061f \u0633\u062a\u062a\u0645 \u0625\u0632\u0627\u0644\u0629 \u0637\u0644\u0628\u0627\u062a\u0643 \u0648\u0633\u064a\u062a\u0645 \u0646\u0642\u0644\u0643 \u0625\u0644\u0649 \u0627\u0644\u0635\u0641\u062d\u0629 \u0627\u0644\u0631\u0626\u064a\u0633\u064a\u0629"
            },
            "restart":{
               "en_US":"RESTART",
               "ar_SA":"\u0625\u0628\u062f\u0623 \u0645\u0646 \u062c\u062f\u064a\u062f"
            },
            "cancel":{
               "en_US":"CANCEL",
               "ar_SA":"\u0625\u0644\u063a\u0627\u0621"
            },
            "customize_food":{
               "en_US":"Customize Food",
               "ar_SA":"\u0627\u0644\u062a\u062e\u0635\u064a\u0635"
            },
            "reset":{
               "en_US":"reset",
               "ar_SA":"\u0625\u0639\u0627\u062f\u0629"
            },
            "add_to_cart":{
               "en_US":"ADD TO CART",
               "ar_SA":"\u0623\u0636\u0641 \u0625\u0644\u0649 \u0627\u0644\u0633\u0644\u0629"
            },
            "added_to_bag":{
               "en_US":"has been added to bag",
               "ar_SA":"\u062a\u0645\u062a \u0625\u0636\u0627\u0641\u062a\u0647 \u0625\u0644\u0649 \u0627\u0644\u0633\u0644\u0629"
            },
            "sar_total":{
               "en_US":"SAR TOTAL",
               "ar_SA":"\u0627\u0644\u0645\u0628\u0644\u063a \u0627\u0644\u0625\u062c\u0645\u0627\u0644\u064a \u0628\u0627\u0644\u0631\u064a\u0627\u0644 "
            },
            "total_inclusive":{
               "en_US":"Total is inclusive of VAT",
               "ar_SA":"\u064a\u0634\u0645\u0644 \u0627\u0644\u0625\u062c\u0645\u0627\u0644\u064a \u0636\u0631\u064a\u0628\u0629 \u0627\u0644\u0642\u064a\u0645\u0629 \u0627\u0644\u0645\u0636\u0627\u0641\u0629"
            },
            "go_to_cart":{
               "en_US":"GO TO CART",
               "ar_SA":"\u0625\u0644\u0649 \u0627\u0644\u0633\u0644\u0629"
            },
            "deliver":{
               "en_US":"Delivery",
               "ar_SA":"\u0627\u0644\u062a\u0648\u0635\u064a\u0644"
            },
            "to-go":{
               "en_US":"Take-out",
               "ar_SA":"\u0637\u0644\u0628 \u0633\u0641\u0631\u064a"
            },
            "curbside":{
               "en_US":"Curbside",
               "ar_SA":"\u0627\u0644\u0627\u0633\u062a\u0644\u0627\u0645 \u0641\u064a \u0627\u0644\u0633\u064a\u0627\u0631\u0629"
            },
            "no_item_found":{
               "en_US":"No Item found",
               "ar_SA":"\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0639\u0646\u0635\u0631"
            },
            "press_back_to_menu":{
               "en_US":"Press on the \u201cBack to menu\u201d button to go back and choose from our menu",
               "ar_SA":"\u0627\u0636\u063a\u0637 \u0639\u0644\u0649 \u0632\u0631 \"\u0625\u0644\u0649 \u0627\u0644\u0642\u0627\u0626\u0645\u0629\" \u0644\u0644\u0639\u0648\u062f\u0629 \u0648\u0627\u0644\u0627\u062e\u062a\u064a\u0627\u0631 \u0645\u0646 \u0642\u0627\u0626\u0645\u062a\u0646\u0627"
            },
            "total_vat_included":{
               "en_US":"_TOTAL_ TOTAL <span>(VAT included)<\/span>",
               "ar_SA":"_TOTAL_ TOTAL <span> (\u0634\u0627\u0645\u0644 \u0636\u0631\u064a\u0628\u0629 \u0627\u0644\u0642\u064a\u0645\u0629 \u0627\u0644\u0645\u0636\u0627\u0641\u0629) <span\/>"
            },
            "place_order":{
               "en_US":"PlACE ORDER",
               "ar_SA":"\u0627\u0631\u0633\u0627\u0644 \u0627\u0644\u0637\u0644\u0628"
            },
            "go_back":{
               "en_US":"GO BACK",
               "ar_SA":"\u0627\u0644\u0639\u0648\u062f\u0629"
            },
            "would_you_like_to_add":{
               "en_US":"Would you like to add these?",
               "ar_SA":"\u0647\u0644 \u062a\u0631\u063a\u0628 \u0641\u064a \u0625\u0636\u0627\u0641\u0629 \u0627\u0644\u0645\u0632\u064a\u062f\u061f"
            },
            "tap_on_any":{
               "en_US":"Tap on any of these if you want to add it to your bag",
               "ar_SA":"\u0627\u0636\u063a\u0637 \u0639\u0644\u0649 \u0623\u064a \u0639\u0646\u0635\u0631 \u0644\u0625\u0636\u0627\u0641\u062a\u0647 \u0625\u0644\u0649 \u0627\u0644\u0633\u0644\u0629"
            },
            "please_select":{
               "en_US":"PLEASE SELECT",
               "ar_SA":"\u064a\u0631\u062c\u0649 \u0627\u0644\u0627\u062e\u062a\u064a\u0627\u0631"
            },
            "eat-in":{
               "en_US":"DINE IN",
               "ar_SA":"\u0637\u0644\u0628 \u0645\u062d\u0644\u064a"
            },
            "order_placed_message":{
               "en_US":"Your Food order have been placed",
               "ar_SA":"\u062a\u0645 \u062a\u0642\u062f\u064a\u0645 \u0637\u0644\u0628\u0643 \u0628\u0646\u062c\u0627\u062d!"
            },
            "payment_declined":{
               "en_US":"Payment Declined!",
               "ar_SA":"\u062a\u0645 \u0631\u0641\u0636 \u0627\u0644\u062f\u0641\u0639!"
            },
            "insert_card_state":{
               "en_US":"Please insert your card",
               "ar_SA":"\u0627\u0644\u0631\u062c\u0627\u0621 \u0625\u062f\u062e\u0627\u0644 \u0628\u0637\u0627\u0642\u062a\u0643"
            },
            "enter_pin_state":{
               "en_US":"Please input your PIN",
               "ar_SA":"\u0627\u0644\u0631\u062c\u0627\u0621 \u0625\u062f\u062e\u0627\u0644 \u0631\u0642\u0645 \u0627\u0644\u062a\u0639\u0631\u064a\u0641 \u0627\u0644\u0634\u062e\u0635\u064a"
            },
            "processing_state":{
               "en_US":"Proccessing your payment, please wait...",
               "ar_SA":"\u062c\u0627\u0631\u064a \u0645\u0639\u0627\u0644\u062c\u0629 \u062f\u0641\u0639\u062a\u0643 \u060c \u064a\u0631\u062c\u0649 \u0627\u0644\u0627\u0646\u062a\u0638\u0627\u0631 ..."
            },
            "default_state":{
               "en_US":"Please follow the payment instructions displayed on the pinpad below",
               "ar_SA":"\u064a\u0631\u062c\u0649 \u0627\u062a\u0628\u0627\u0639 \u062a\u0639\u0644\u064a\u0645\u0627\u062a \u0627\u0644\u062f\u0641\u0639 \u0627\u0644\u0645\u0648\u0636\u062d\u0629 \u0623\u062f\u0646\u0627\u0647"
            },
            "idle_title":{
               "en_US":"You\u2019ve been idle",
               "ar_SA":"\u0644\u0645 \u064a\u0643\u0646 \u0647\u0646\u0627\u0643 \u0623\u064a \u0644\u0645\u0633\u0627\u062a \u0639\u0644\u0649 \u0627\u0644\u0634\u0627\u0634\u0629"
            },
            "idle_subtitle":{
               "en_US":"We\u2019ve noticed you haven\u2019t touched the screen for a while now. Would you like to restart this order?",
               "ar_SA":"\u0644\u0642\u062f \u0644\u0627\u062d\u0638\u0646\u0627 \u0623\u0646\u0643 \u0644\u0645 \u062a\u0644\u0645\u0633 \u0627\u0644\u0634\u0627\u0634\u0629 \u0645\u0646\u0630 \u0641\u062a\u0631\u0629 \u062d\u062a\u0649 \u0627\u0644\u0622\u0646. \u0647\u0644 \u062a\u0631\u063a\u0628 \u0641\u064a \u0628\u062f\u0621 \u0647\u0630\u0627 \u0627\u0644\u0637\u0644\u0628 \u0645\u0646 \u0627\u0644\u0628\u062f\u0627\u064a\u0629\u061f"
            },
            "idle_seconds":{
               "en_US":"Will automatically restart in _SECONDS_",
               "ar_SA":"\u0625\u0639\u0627\u062f\u0629 \u0627\u0644\u062a\u0634\u063a\u064a\u0644 \u062a\u0644\u0642\u0627\u0626\u064a\u064b\u0627 \u0641\u064a  _SECONDS_"
            }
         }
      }
   ],
   "paging":{
      "total":1,
      "count":1,
      "pageCount":1,
      "next":null,
      "previous":null
   }
}